import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import merge from 'lodash/merge';
import { Ribbon, RibbonGroup, RibbonGroupItem, RibbonButton } from "react-bootstrap-ribbon";
import { menu } from '../../../main/js/router/Menu';
import { i18ndb } from '../services';
import { UPDATE_RIBBON_EVENT } from '../events/Gui'

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-ribbon/dist/react-bootstrap-ribbon.css";
import './ribbon.css'
import '../pages/Form.css'

const buildOptions = (options) => {
    let items = [];
    let colClass = "col-" + Math.round(12/Object.entries(options).length)
    Object.entries(options).forEach(([option, descriptor]) => {
        items.push(
        <RibbonGroupItem colClass={colClass} key={option}>
            <RibbonButton onClick= {descriptor.do} >
				{descriptor.icon ? descriptor.icon : ""}
                <div>{i18ndb._(descriptor.label)}</div>
            </RibbonButton>
        </RibbonGroupItem>
        )  
    });
    
    return items;
}

const buildGroups = (subMenu) => {
    let items = [];
    const subMenuKeys = Object.keys(subMenu);
    let colClass = "col-" + Math.round(12/subMenuKeys.length);
    subMenuKeys.forEach((key, index) => {
        let options = buildOptions(subMenu[key].options)
        items.push(
            <RibbonGroup title={i18ndb._(subMenu[key].label)} key={key} colClass={colClass}>
                {options}
            </RibbonGroup>
        )
        
    });
    return items;
}

const buildMenu = (options) => {
  const items = []
  const optionsKeys = Object.keys(options);
  optionsKeys.forEach((key, index) => {
    items.push(
      <Tab eventKey={key} title={i18ndb._(options[key].label)} key={key}>
        <RibbonComponent subMenu={options[key]["submenu"]} />
      </Tab>
    )
  });
	return items;
}

const RibbonComponent = (props) => {
    const groups = buildGroups(props.subMenu)
    return (
        <Ribbon breakpoint="lg">
            {groups}
        </Ribbon>
    )
}

export function RibbonTab() {
    const [key, setKey] = useState(Object.keys(menu)[0]);
    const [ribbon, setRibbon] = useState(buildMenu(menu()));

	UPDATE_RIBBON_EVENT.subscribe( (m)=>setRibbon(m) );

    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
		{ribbon}
      </Tabs>
    );
}

export function setContextualOptions(options) {
	let m = merge(menu(), options);
	UPDATE_RIBBON_EVENT.publish(buildMenu(m));
}